import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import * as serviceWorker from './serviceWorker'
import App from './App'

const rootElement = document.getElementById('root')
ReactGA.initialize('UA-71806598-9')
ReactGA.pageview(window.location.pathname + window.location.search)
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
)

serviceWorker.register()
