import React from 'react'
import { Select, Loader, Message } from 'semantic-ui-react'
import { ResponsiveBar } from '@nivo/bar'

const API_STATUS = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  FAILURE: 4
}

class WICounty extends React.Component {
  constructor (props) {
    super(props)
    this.getCountyList = this.getCountyList.bind(this)
    this.handleSelectedCounty = this.handleSelectedCounty.bind(this)
    this.state = {
      county: 'Milwaukee',
      countyList: [],
      countyData: null,
      apiStatus: API_STATUS.NOT_STARTED
    }
  }

  getCountyList () {
    return (
      this.state.countyList &&
      this.state.countyList.map((item) => {
        return {
          key: item,
          value: item,
          text: item
        }
      })
    )
  }

  getColorForMilData (bar) {
    const colors = {
      Positive: '#e8c1a0',
      Deaths: 'red'
    }
    return colors[bar.id]
  }

  getFormattedDataForLegend (data) {
    return data && data.map(item => ({
      ...item,
      Deaths: item.DTH_NEW,
      Positive: item.POS_NEW
    }))
  }

  handleSelectedCounty (e, data) {
    this.setState({
      apiStatus: API_STATUS.IN_PROGRESS
    })
    fetch(
      `https://dhsgis.wi.gov/server/rest/services/DHS_COVID19/COVID19_WI/MapServer/12/query?where=GEO%20%3D%20%27COUNTY%27%20AND%20NAME%20%3D%20%27${data.value}%27&outFields=NAME,DATE,NEGATIVE,POSITIVE,DEATHS,DTH_NEW,POS_NEW,NEG_NEW,TEST_NEW,DATE&outSR=4326&orderByFields=DATE+desc&resultRecordCount=10&f=json`
    )
      .then((data) => data.json())
      .then((jsonData) => {
        this.setState({
          countyData: this.getFormattedDataForLegend(jsonData.features
            .map((item) => item.attributes)
            .reverse()),
          county: data.value,
          apiStatus: API_STATUS.COMPLETED
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          apiStatus: API_STATUS.FAILURE
        })
      })
  }

  /* componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error);
    console.log(errorInfo);
  } */
  async componentDidMount () {
    try {
      this.setState({
        apiStatus: API_STATUS.IN_PROGRESS
      })
      const result = await Promise.all([
        fetch(
          'https://dhsgis.wi.gov/server/rest/services/DHS_COVID19/COVID19_WI/MapServer/12/query?where=DATE%20%3E%3D%20TIMESTAMP%20%272020-06-12%2000%3A00%3A00%27%20AND%20DATE%20%3C%3D%20TIMESTAMP%20%272020-06-12%2023%3A59%3A59%27%20AND%20GEO%20%3D%20%27County%27&outFields=NAME&outSR=4326&orderByFields=DATE+desc&f=json'
        ).then((data) => data.json()),
        fetch(
          `https://dhsgis.wi.gov/server/rest/services/DHS_COVID19/COVID19_WI/MapServer/12/query?where=GEO%20%3D%20%27COUNTY%27%20AND%20NAME%20%3D%20%27${this.state.county}%27&outFields=NAME,DATE,NEGATIVE,POSITIVE,DEATHS,DTH_NEW,POS_NEW,NEG_NEW,TEST_NEW,DATE&outSR=4326&orderByFields=DATE+desc&resultRecordCount=10&f=json`
        ).then((data) => data.json())
      ])
      this.setState({
        countyList: result[0].features.map((item) => item.attributes.NAME),
        countyData: this.getFormattedDataForLegend(result[1].features.map((item) => item.attributes).reverse()),
        apiStatus: API_STATUS.COMPLETED
      })
    } catch (error) {
      console.log(error)
      this.setState({
        apiStatus: API_STATUS.FAILURE
      })
    }
  }

  render () {
    const { countyData, county, apiStatus } = this.state
    if (apiStatus === API_STATUS.IN_PROGRESS) {
      return (
        <Loader active inline>Loading</Loader>
      )
    } else if (apiStatus === API_STATUS.COMPLETED && countyData) {
      return (
        <>
          <h2>County Data - Daily Increase</h2>
          <Select
            value={county}
            placeholder='Select county'
            options={this.getCountyList()}
            onChange={this.handleSelectedCounty}
          />
          <ResponsiveBar
            data={countyData}
            keys={['Positive', 'Deaths']}
            indexBy='DATE'
            margin={{ top: 70, right: 10, bottom: 25, left: 40 }}
            padding={0.3}
            axisBottom={{
              format: (values) => {
                const dateChecked = new Date(values)
                return `${dateChecked.getMonth() + 1}/${dateChecked.getDate()}`
              }
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                itemDirection: 'left-to-right',
                translateX: 0,
                translateY: -60,
                itemsSpacing: 2,
                itemHeight: 20,
                symbolSize: 20
              }
            ]}
            colors={this.getColorForMilData}
          />
        </>
      )
    } else if (apiStatus === API_STATUS.FAILURE) {
      return (
        <Message color='red'>
          Unable to fetch county data. Please try to refresh the app.
        </Message>
      )
    } else return null
  }
}

export default WICounty
