import React, { Component } from 'react'
import { Dimmer, Loader, Message } from 'semantic-ui-react'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import WIHeader from './WIHeader'
import WICounty from './WICounty'

const API_STATUS = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  FAILURE: 4
}

class WICovid extends Component {
  constructor (props) {
    super(props)
    this.getTestData = this.getTestData.bind(this)
    this.getRecoveryData = this.getRecoveryData.bind(this)
    this.getPositiveDataByAge = this.getPositiveDataByAge.bind(this)
    this.state = {
      wisconsinData: null,
      mkeData: null,
      apiStatus: API_STATUS.NOT_STARTED
    }
  }

  getDHSWIFormattedData (dhsWIData) {
    const finalDHSWIData = []
    dhsWIData.features.forEach((item, index) => {
      finalDHSWIData.push({
        ...item.attributes,
        positiveIncrease: item.attributes.POS_NEW,
        hospitalizedIncrease: `${
          index < dhsWIData.features.length - 1
            ? item.attributes.HOSP_YES -
              dhsWIData.features[index + 1].attributes.HOSP_YES
            : 0
        }`,
        deathIncrease: item.attributes.DTH_NEW,
        dateChecked: item.attributes.DATE,
        totalTestResultsIncrease: item.attributes.TEST_NEW,
        positive: item.attributes.POSITIVE,
        hospitalizedCumulative: item.attributes.HOSP_YES,
        death: item.attributes.DEATHS,
        inIcuCumulative: item.attributes.IC_YES,
        negative: item.attributes.NEGATIVE
      })
    })
    return finalDHSWIData.reverse().slice(1, 11)
  }

  async componentDidMount () {
    this.setState({
      apiStatus: API_STATUS.IN_PROGRESS
    })
    try {
      const result = await Promise.all([
        fetch(
          'https://dhsgis.wi.gov/server/rest/services/DHS_COVID19/COVID19_WI/MapServer/12/query?where=GEO%20%3D%20%27COUNTY%27%20AND%20NAME%20%3D%20%27MILWAUKEE%27&outFields=NAME,DATE,NEGATIVE,POSITIVE,DEATHS,DTH_NEW,POS_NEW,NEG_NEW,TEST_NEW,DATE&outSR=4326&orderByFields=DATE+desc&resultRecordCount=10&f=json'
        ).then((data) => data.json()),
        fetch(
          'https://dhsgis.wi.gov/server/rest/services/DHS_COVID19/COVID19_WI/MapServer/11/query?where=GEO%20%3D%20%27State%27%20AND%20NAME%20%3D%20%27WI%27&outFields=NAME,DATE,NEGATIVE,POSITIVE,DEATHS,DTH_NEW,POS_NEW,NEG_NEW,TEST_NEW,HOSP_YES,IC_YES,POS_0_9,POS_10_19,POS_20_29,POS_30_39,POS_40_49,POS_50_59,POS_60_69,POS_70_79,POS_80_89,POS_90,DATE&outSR=4326&orderByFields=DATE+desc&resultRecordCount=11&f=json'
        ).then((data) => data.json())
      ])
      this.setState({
        mkeData: result[0].features.map((item) => item.attributes).reverse(),
        wisconsinData: this.getDHSWIFormattedData(result[1]),
        apiStatus: API_STATUS.COMPLETED
      })
    } catch (error) {
      this.setState({
        apiStatus: API_STATUS.FAILURE
      })
    }
  }

  getColorForWiscData (bar) {
    const colors = {
      positiveIncrease: '#e8c1a0',
      hospitalizedIncrease: 'orange',
      deathIncrease: 'red'
    }
    return colors[bar.id]
  }

  getPositiveDataByAge () {
    const POS_0_9 = []
    const POS_10_19 = []
    const POS_20_29 = []
    const POS_30_39 = []
    const POS_40_49 = []
    const POS_50_59 = []
    const POS_60_69 = []
    const POS_70_79 = []
    const POS_80_89 = []
    const POS_90 = []
    this.state.wisconsinData.forEach((item) => {
      const dateTest = new Date(item.dateChecked)
      POS_0_9.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_0_9)
      })
      POS_10_19.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_10_19)
      })
      POS_20_29.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_20_29)
      })
      POS_30_39.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_30_39)
      })
      POS_40_49.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_40_49)
      })
      POS_50_59.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_50_59)
      })
      POS_60_69.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_60_69)
      })
      POS_70_79.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_70_79)
      })
      POS_80_89.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_80_89)
      })
      POS_90.push({
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: Number(item.POS_90)
      })
    })
    const finalPositiveData = [
      {
        data: POS_0_9,
        id: '0-9'
      },
      {
        data: POS_10_19,
        id: '10-19'
      },
      {
        data: POS_20_29,
        id: '20-29'
      },
      {
        data: POS_30_39,
        id: '30-39'
      },
      {
        data: POS_40_49,
        id: '40-49'
      },
      {
        data: POS_50_59,
        id: '50-59'
      },
      {
        data: POS_60_69,
        id: '60-69'
      },
      {
        data: POS_70_79,
        id: '70-79'
      },
      {
        data: POS_80_89,
        id: '80-89'
      },
      {
        data: POS_90,
        id: '90+'
      }
    ]
    return finalPositiveData
  }

  getColorForMilData (bar) {
    const colors = {
      POS_NEW: '#e8c1a0',
      DTH_NEW: 'red'
    }
    return colors[bar.id]
  }

  getTestData () {
    const finalTestData = []
    const mkeTestData = this.state.mkeData.map((item) => {
      const dateTest = new Date(item.DATE)
      return {
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: item.TEST_NEW
      }
    })
    finalTestData.push({
      data: mkeTestData,
      color: 'hsl(25, 70%, 50%)',
      id: 'Milwaukee'
    })
    const wiscTestData =
      this.state.wisconsinData &&
      this.state.wisconsinData.map((item) => {
        const dateTest = new Date(item.dateChecked)
        return {
          x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
          y: item.totalTestResultsIncrease
        }
      })
    finalTestData.push({
      data: wiscTestData,
      color: 'hsl(331, 70%, 50%)',
      id: 'Wisconsin'
    })
    /* if (finalTestData[0].data[0]["x"] !== finalTestData[1].data[0]["x"]) {
      delete finalTestData[1].data[finalTestData[1].data.length - 1];
    } */
    return finalTestData
  }

  getRecoveryData () {
    const finalRecoveryData = []
    const wiRecoveryData = this.state.wisconsinData.map((item) => {
      const dateTest = new Date(item.dateChecked)
      return {
        x: `${dateTest.getMonth() + 1}/${dateTest.getDate()}`,
        y: item.recovered
      }
    })
    finalRecoveryData.push({
      data: wiRecoveryData,
      color: 'green',
      id: 'Wisconsin Recovery'
    })
    return finalRecoveryData
  }

  render () {
    const { apiStatus, wisconsinData } = this.state
    if (apiStatus === 3) {
      return (
        <>
          <h2>Wisconsin Covid19 Data</h2>
          <WIHeader currentData={wisconsinData[wisconsinData.length - 1]} />
          <hr />
          <h2>Wisconsin - Daily Increase</h2>
          <ResponsiveBar
            data={wisconsinData}
            keys={['positiveIncrease', 'hospitalizedIncrease', 'deathIncrease']}
            indexBy='dateChecked'
            margin={{ top: 100, right: 10, bottom: 25, left: 40 }}
            padding={0.3}
            axisBottom={{
              format: (values) => {
                const dateChecked = new Date(values)
                return `${dateChecked.getMonth() + 1}/${dateChecked.getDate()}`
              }
            }}
            legends={[
              {
                data: [
                  {
                    color: '#e8c1a0',
                    id: 'positiveIncrease',
                    label: 'Positive Increase'
                  },
                  {
                    color: 'orange',
                    id: 'hospitalizedIncrease',
                    label: 'Hospitalized Increase'
                  },
                  {
                    color: 'red',
                    id: 'deathIncrease',
                    label: 'Death Increase'
                  }
                ],
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                itemDirection: 'left-to-right',
                translateX: 0,
                translateY: -70,
                itemsSpacing: 2,
                itemHeight: 20,
                symbolSize: 20
              }
            ]}
            colors={this.getColorForWiscData}
          />
          <hr />
          <WICounty />
          <hr />
          <h2>Wisconsin Testing Data</h2>
          <ResponsiveLine
            data={this.getTestData()}
            margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: true,
              reverse: false
            }}
            axisBottom={{
              orient: 'bottom'
            }}
            colors={{ scheme: 'nivo' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel='y'
            pointLabelYOffset={-12}
            useMesh
            legends={[
              {
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
          <hr />
          <h2>Wisconsin Positive Data by Age</h2>
          <ResponsiveLine
            data={this.getPositiveDataByAge()}
            margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false
            }}
            axisBottom={{
              orient: 'bottom'
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'count',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel='y'
            pointLabelYOffset={-12}
            useMesh
            legends={[
              {
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
          <hr />
        </>
      )
    } else if (apiStatus === 2) {
      return (
        <Dimmer active>
          <Loader>Loading</Loader>
        </Dimmer>
      )
    } else if (apiStatus === API_STATUS.FAILURE) {
      return (
        <Message color='red'>
          Unable to fetch data. Please try to refresh the app or try after some
          time.
        </Message>
      )
    } else {
      return null
    }
  }
}

export default WICovid
