import React from 'react'
import './styles.css'
import WICovid from './WICovid'

export default function App () {
  return (
    <div className='App' style={{ height: '95vh' }}>
      <WICovid />
    </div>
  )
}
