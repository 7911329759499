import React from 'react'
import { Table, Responsive, Statistic } from 'semantic-ui-react'

const formatVal = val => val && val.toLocaleString()

export default ({ currentData }) => {
  const {
    positive,
    negative,
    hospitalizedCumulative,
    inIcuCumulative,
    death
  } = currentData
  return (
    <>
      <Responsive {...Responsive.onlyMobile}>
        <Statistic.Group size='tiny'>
          <Statistic>
            <Statistic.Value>{formatVal(positive)}</Statistic.Value>
            <Statistic.Label>Positive</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>
              {formatVal(hospitalizedCumulative)}
            </Statistic.Value>
            <Statistic.Label>Hospitalized</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{formatVal(inIcuCumulative)}</Statistic.Value>
            <Statistic.Label>ICU</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{formatVal(death)}</Statistic.Value>
            <Statistic.Label>Death</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Responsive>
      <Responsive {...Responsive.onlyComputer}>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Cases</Table.HeaderCell>
              <Table.HeaderCell>Negative Tests</Table.HeaderCell>
              <Table.HeaderCell>Cumulative Hospitalized</Table.HeaderCell>
              <Table.HeaderCell>Cumulative ICU</Table.HeaderCell>
              <Table.HeaderCell>Deaths</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{formatVal(positive)}</Table.Cell>
              <Table.Cell>{formatVal(negative)}</Table.Cell>
              <Table.Cell>{formatVal(hospitalizedCumulative)}</Table.Cell>
              <Table.Cell>{formatVal(inIcuCumulative)}</Table.Cell>
              <Table.Cell>{formatVal(death)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Responsive>
    </>
  )
}
